<template>
  <v-col
    v-if="progressStore.progress !== true"
    cols="12"
    sm="12"
  >
    <v-row dense>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <profile-entry :section="1" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import ProfileEntry from '@/components/ProfileEntry';

export default {
  name: 'ProfileCheck',
  components: {
    ProfileEntry
  },
  mixins: [mixin],
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState({
      progressStore: (state) => state.progressStore,
    }),
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true)
      this.$store.dispatch('usersStore/resetProfile');
      await this.$store.dispatch('navigationStore/clearNavigation');
      await this.$store.dispatch('navigationStore/clearNavigationAdmin');
      window.localStorage.setItem('appMode', 3);
      await this.$store.dispatch('usersStore/setAppMode', 3);
      this.$store.dispatch('progressStore/set',false)
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
